import React from "react";
import { RESULTS } from "resources/results";
import Layout from "../components/Layout";
import { Chart } from "react-google-charts";
import ScrollFade from "components/container/ScrollFade";
import Container from "components/Container";

const ResultsPage = () => {
  const data = []
  Object.keys(RESULTS).map((key) => {
    const row = [];
    row.push(key);
    const memebers = Object.keys(RESULTS[key]).map(() => RESULTS[key]);
    const [key1] = Object.entries(memebers[0].sec);
    const [key2] = Object.entries(memebers[0].dysec);
    const sum = key1[1] + key2[1];
    row.push(sum);
    data.push(row);
  });

  console.log(Object.keys(RESULTS))

  data.unshift(["Houses", "Votes"])
  const options = {
    title: "",
  };
  return (
    <Layout>
      <Container bgColor="bg-color-maroon">
        <ScrollFade>
          <p className="text-white text-center text-capitalize display-4 mt-2">UHC 2023 Election Results</p>

          {/* <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/DordJ8Ep1bI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
          {/* <p className="text-center text-white mt-3">Click on the candidate to know more</p> */}
        </ScrollFade>
      </Container>
      <Container>
        <table className="table table-striped table-sm table-bordered shadow">
          <thead>
            <tr>
              <th scope="col">House name</th>
              <th scope="col">Secretary winner</th>
              <th scope="col">Dep secretary winner</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(RESULTS).sort().map((key) => {
              console.log(key)
              const memebers = Object.keys(RESULTS[key]).map(() => RESULTS[key]);
              const [key1] = Object.entries(memebers[0].sec);
              const [key2] = Object.entries(memebers[0].dysec);
              return (
                <tr key={key}>
                  <td>
                    <b>{key}</b>
                  </td>
                  <td>
                    <a href={"mailto:" + key1[0].split(" - ")[0]}>{key1[0].split(" - ")[1]}</a>
                  </td>
                  <td>
                    <a href={"mailto:" + key2[0].split(" - ")[0]}>{key2[0].split(" - ")[1]}</a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
      {/* <div className="table-responsive container-lg">
        
      </div> */}
      <Container bgColor="bg-color-maroon">
        <p className="text-white text-center text-capitalize display-6 mt+-2">Voter turnout per house</p>

      {/* <div className="rounded"> */}
        <Chart chartType="PieChart" data={data} options={options} width={"100%"} height={"500px"} />
      {/* </div> */}
      </Container>
    </Layout>
  );
};

export default ResultsPage;
