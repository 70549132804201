export const RESULTS = {
  Bandipur: {
    sec: {
      "22f1001634@ds.study.iitm.ac.in - Arka Banerjee ": 61,
    },
    dysec: {
      "21f2001304@ds.study.iitm.ac.in - Shivang Kumar ": 149,
    },
  },
  Gir: {
    sec: {
      "22f3001240@ds.study.iitm.ac.in - Vighnesh Mishra": 70,
    },
    dysec: {
      "22f1000659@ds.study.iitm.ac.in - Prem Kumar Gupta": 70,
    },
  },
  Kaziranga: {
    sec: {
      "21f3000432@ds.study.iitm.ac.in - Adamya Vatsalya Sharma ": 72,
    },
    dysec: {
      "21f1002300@ds.study.iitm.ac.in - D Chirag Rao ": 72,
    },
  },
  Kanha: {
    sec: {
      "22f3001792@ds.study.iitm.ac.in - Aditya Siddharth Jyoti": 86,
    },
    dysec: {
      "22f2001290@ds.study.iitm.ac.in - Deepesh Kumar Dawar": 163,
    },
  },
  Wayanad: {
    sec: {
      "21f1005152@ds.study.iitm.ac.in - Advait Vats": 118,
    },
    dysec: {
      "22f3000301@ds.study.iitm.ac.in - Pritesh Gupta ": 118,
    },
  },
  Saranda: {
    sec: {
      "21f2000864@ds.study.iitm.ac.in - Manidipa Roy": 28,
    },
    dysec: {
      "None Selected": 43,
    },
  },
  Nilgiri: {
    sec: {
      "21f1003070@ds.study.iitm.ac.in - Soumyabrata Mahapatra": 117,
    },
    dysec: {
      "21f3003023@ds.study.iitm.ac.in - Somsankar Chakraborty ": 56,
    },
  },
  Pichavaram: {
    sec: {
      "22f2000169@ds.study.iitm.ac.in - Arnold Mathew": 123,
    },
    dysec: {
      "22f3001864@ds.study.iitm.ac.in - Rupkatha Suter": 68,
    },
  },
  Corbett: {
    sec: {
      "22f2000931@ds.study.iitm.ac.in - GANATRA DARSHAN": 69,
    },
    dysec: {
      "21f1000485@ds.study.iitm.ac.in - Puppala Bala Sai Manikanta Sandeep": 48,
    },
  },
  Namdapha: {
    sec: {
      "21f1004392@ds.study.iitm.ac.in - Shivam Trivedi": 74,
    },
    dysec: {
      "21f3001936@ds.study.iitm.ac.in - Sarthak Singh Gaur": 74,
    },
  },
  Nallamala: {
    sec: {
      "22f1001058@ds.study.iitm.ac.in - Harsh Patel": 96,
    },
    dysec: {
      "22f3002043@ds.study.iitm.ac.in - Shivam Raj": 96,
    },
  },
  Sundarbans: {
    sec: {
      "22f1001774@ds.study.iitm.ac.in - Abhishek ojha ": 66,
    },
    dysec: {
      "21f3002792@ds.study.iitm.ac.in - Ravi Kant ": 42,
    },
  },
};

